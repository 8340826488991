.App {
  text-align: center;
  
.dlpd-root .call-view, .dlpd-root .incoming-call-view{
  height: 410px !important;
  background-image: url('/public/bg.jpg');
}

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dlpd-root {
  width: 100% !important;
}
.dlpd-root .call-view, .dlpd-root .incoming-call-view{
  height: 100% !important;
  background-image: url('/public/bg.jpg') !important;
}
.dlpd-root .buttons .button{
  margin: 3.5% 6% !important;
  background-color: transparent;
  border: 1px solid rgba(355, 355, 355, 0.2);
}
.dlpd-root .initial-view .display{
  height: 20% !important;
}
.dlpd-root .call-view .contact .name, .dlpd-root .incoming-call-view .contact .name{
  display: none !important;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full height of the viewport */
  width: 100%; /* Full width of the viewport */
  box-sizing: border-box; /* Include padding in width/height calculations */
  
  max-width: 500px;
  margin: auto;
}

.react-dialpad {
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

/* Optional: Styles for mobile */
@media (max-width: 768px) {
  .dialpad-button {
      width: 100%; /* Full-width buttons */
      font-size: 20px; /* Adjust button font size */
  }

  .dialpad-display {
      font-size: 24px; /* Larger display text */
  }
}

.dlpd-root .initial-view .keypad{
  align-content: center;
}